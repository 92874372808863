/* Additional vertical padding used by kbd tag. */
.py-05 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.markdown {
  @apply break-words leading-normal text-gray-900;
}

.markdown > * + * {
  @apply mb-4 mt-0;
}

.markdown li + li {
  @apply mt-1;
}

.markdown li > p + p {
  @apply mt-6;
}

.markdown strong {
  @apply font-semibold;
}

.markdown a {
  @apply font-semibold text-blue-600;
}

.markdown strong a {
  @apply font-bold;
}

.markdown h1 {
  @apply mb-4 mt-6 border-b pb-2 text-4xl font-semibold leading-tight;
}

.markdown h2 {
  @apply mb-4 mt-6 border-b pb-2 text-2xl font-semibold leading-tight;
}

.markdown h3 {
  @apply mb-4 mt-6 text-lg font-semibold leading-snug;
}

.markdown h4 {
  @apply mb-4 mt-6 text-base font-semibold leading-none;
}

.markdown h5 {
  @apply mb-4 mt-6 text-sm font-semibold leading-tight;
}

.markdown h6 {
  @apply mb-4 mt-6 text-sm font-semibold leading-tight text-gray-600;
}

.markdown blockquote {
  @apply border-l-4 border-gray-300 pl-4 pr-4 text-base text-gray-600;
}

.markdown code {
  @apply py-05 inline rounded bg-gray-200 px-1 font-mono text-sm;
}

.markdown pre {
  @apply rounded bg-gray-100 p-4;
}

.markdown pre code {
  @apply block overflow-visible rounded-none bg-transparent p-0;
}

.markdown ul {
  @apply list-disc pl-8;
}

.markdown ol {
  @apply list-decimal pl-8;
}

.markdown kbd {
  @apply py-05 inline-block rounded border px-1 align-middle font-mono text-xs font-normal shadow;
}

.markdown table {
  @apply border-gray-600 text-base;
}

.markdown th {
  @apply border px-3 py-1;
}

.markdown td {
  @apply border px-3 py-1;
}

/* Override pygments style background color. */
.markdown .highlight pre {
  @apply bg-gray-100 !important;
}

.markdown.markdown-mobile-tiny h1 {
  @apply !text-[14px] ![line-height:16px] sm:!text-4xl;
}
.markdown.markdown-mobile-tiny h2 {
  @apply !text-[12px] ![line-height:14px] sm:!text-2xl;
}
.markdown.markdown-mobile-tiny h3 {
  @apply !text-[10px] ![line-height:12px] sm:!text-lg;
}
.markdown.markdown-mobile-tiny h4 {
  @apply !text-[8px] ![line-height:10px] sm:!text-base;
}
.markdown.markdown-mobile-tiny h5 {
  @apply !text-[8px] ![line-height:10px] sm:!text-sm;
}
.markdown.markdown-mobile-tiny h6 {
  @apply !text-[8px] ![line-height:10px] sm:!text-sm;
}
.markdown.markdown-mobile-tiny p,
.markdown.markdown-mobile-tiny ul,
.markdown.markdown-mobile-tiny ol,
.markdown.markdown-mobile-tiny code,
.markdown.markdown-mobile-tiny pre,
.markdown.markdown-mobile-tiny table,
.markdown.markdown-mobile-tiny blockquote {
  @apply !text-[8px] ![line-height:10px] sm:!text-base;
}
