@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .masonry-3-col {
    column-count: 3;
    column-gap: 1em;
  }

  .masonry-2-col {
    column-count: 2;
    column-gap: 1em;
  }

  .break-inside {
    break-inside: avoid;
  }
}

div[role='graphics-document'] svg {
  /* NOTE: depending on other configuration settings it's possible that these */
  /* may cause the SVG aspect ratio will be deformed, causing issues with mouse */
  /* event positioning. */

  /* NOTE: these are also related to the Vega lite "autosize". */

  /* NOTE: this also matches the fallback image screenshot to minimize layout */
  /* shifts. */

  /* NOTE: this is 'prop-drilling' w-full. */
  width: 100%;
  height: 100%;
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.break-nicely {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.speech-bubble {
  position: relative;
  background: #ecfeff;
  border-color: #e0f2fe;
  border-style: solid;
  border-width: 1px;
}

.speech-bubble:after {
  border-color: #ecfeff;
  border-style: solid;
  border-width: 1px;
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-right-color: #ecfeff;
  border-left: 0;
  border-top: 0;
  margin-top: -12.5px;
  margin-left: -25px;
}
